<template>
  <Article class="contact" title="Kontakt">
    <form
      v-if="!submitSuccess"
      class="contact-form"
      method="post"
      action="/.netlify/functions/contact"
      @submit="submit"
    >
      <FormField name="name" label="Navn" v-model="state.name" required />
      <FormField
        name="email"
        format="email"
        label="E-post"
        v-model="state.email"
        required
      />
      <FormField
        format="number"
        name="phone"
        label="Telefon (valgfritt)"
        v-model="state.phone"
      />
      <FormField
        name="message"
        label="Melding"
        type="textarea"
        required
        v-model="state.message"
      />
      <div class="error" v-if="submitFailed">
        Klarte ikke sende inn meldingen.
      </div>
      <Button type="submit">Send melding</Button>
    </form>
  </Article>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from "vue";
import FormField from "@/components/Form/FormField.vue";
import Button from "@/components/Button/Button.vue";
import axios from "axios";
import Router from "@/router";
export default defineComponent({
  name: "page-contact",
  setup() {
    const submitSuccess = ref(false);
    const submitFailed = ref(false);

    const state = reactive({
      name: "",
      email: "",
      phone: "",
      message: "",
    });

    const submit = (event: Event) => {
      event.preventDefault();
      submitFailed.value = false;

      axios
        .post(`${process.env.VUE_APP_BACKEND}/sh/contact`, state)
        .then(() => {
          submitSuccess.value = true;
          Router.push("/kontakt/bekreftelse");
        })
        .catch(() => {
          submitSuccess.value = false;
          submitFailed.value = true;
        });
    };

    return {
      submitSuccess,
      submitFailed,
      submit,
      state,
    };
  },
  components: {
    FormField,
    Button,
  },
});
</script>

<style lang="scss" scoped>
.error {
  background: var(--shx-red);
  padding: 1rem 2rem;
  border-radius: 0.3rem;
  margin: 1rem 0;
}

button {
  font-size: 1.8rem;
  color: var(--shx-blue);
  background: #89ffd7;
  padding: 0.8rem 2rem;
  border: none;
  border-radius: 0.3rem;
  margin: 1rem 0;
  align-self: start;
  font-weight: 600;
}

.contact-form {
  width: 100%;
  max-width: 40rem;
  margin: 0 auto;
}
</style>
