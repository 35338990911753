
import { defineComponent } from "vue";
import uuid from "@/utils/uuid";

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      type: [String, Number],
      required: false,
    },
    format: {
      type: [String],
      required: false,
      default: "text",
    },
    id: {
      type: String,
      default: uuid,
    },
    type: {
      default: "input",
      required: false,
    },
    required: {
      default: false,
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const updateValue = (event: InputEvent) => {
      const input = event.currentTarget as HTMLTextAreaElement;
      emit("update:modelValue", input.value);
    };

    return {
      updateValue,
    };
  },
});
