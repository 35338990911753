
import { defineComponent, reactive, ref } from "vue";
import FormField from "@/components/Form/FormField.vue";
import Button from "@/components/Button/Button.vue";
import axios from "axios";
import Router from "@/router";
export default defineComponent({
  name: "page-contact",
  setup() {
    const submitSuccess = ref(false);
    const submitFailed = ref(false);

    const state = reactive({
      name: "",
      email: "",
      phone: "",
      message: "",
    });

    const submit = (event: Event) => {
      event.preventDefault();
      submitFailed.value = false;

      axios
        .post(`${process.env.VUE_APP_BACKEND}/sh/contact`, state)
        .then(() => {
          submitSuccess.value = true;
          Router.push("/kontakt/bekreftelse");
        })
        .catch(() => {
          submitSuccess.value = false;
          submitFailed.value = true;
        });
    };

    return {
      submitSuccess,
      submitFailed,
      submit,
      state,
    };
  },
  components: {
    FormField,
    Button,
  },
});
