<template>
  <div class="form-field">
    <label :for="id">{{ label }}</label>
    <component
      class="form-field-input"
      :is="type"
      :type="format"
      :name="name"
      :id="id"
      :value="modelValue"
      :required="required"
      @input="updateValue"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import uuid from "@/utils/uuid";

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      type: [String, Number],
      required: false,
    },
    format: {
      type: [String],
      required: false,
      default: "text",
    },
    id: {
      type: String,
      default: uuid,
    },
    type: {
      default: "input",
      required: false,
    },
    required: {
      default: false,
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const updateValue = (event: InputEvent) => {
      const input = event.currentTarget as HTMLTextAreaElement;
      emit("update:modelValue", input.value);
    };

    return {
      updateValue,
    };
  },
});
</script>

<style lang="scss" scoped>
.form-field {
  margin-bottom: 2rem;
}

label {
  display: block;
  font-weight: 700;
}

.form-field-input {
  padding: 1.1rem 2rem;
  border: none;
  font-size: 1.6rem;
  color: #fff;
  width: 100%;
  max-width: 40rem;
  background: var(--shx-blue);
  border-bottom: 0.2rem solid #454d5f;
  box-shadow: none;
  appearance: none;
  font-family: "Raleway", sans-serif;
  font-family: "Raleway", sans-serif;

  &:focus {
    border-bottom-color: var(--shx-orange);
    outline: none;
  }
}

.form-field-input:-webkit-autofill,
.form-field-input:-webkit-autofill:hover,
.form-field-input:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff;
  transition: background-color 5000s ease-in-out 0s;
}

textarea {
  min-height: 15rem;
}
</style>
