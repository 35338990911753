<template>
  <Article class="confirmation">
    <h1>Meldingen er sendt!</h1>
    <span class="subtitle">
      Du hører fra meg snart! Ha en fantastisk dag!
    </span>
  </Article>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
});
</script>

<style lang="scss" scoped>
.confirmation {
  text-align: center;
  transform: translateY(-50%);
  margin-top: 50%;
}

.subtitle {
  font-size: 1.8rem;
  text-align: center;
}
</style>
